@font-face {
    font-family: 'Streetwear';
    src: url('./assets/fonts/Streetwear.otf')
  }
  @font-face {
    font-family: 'Playlist-Script';
    src: url('./assets/fonts/Playlist-Script.otf')
  }
  @font-face {
    font-family: 'Pacifico-Regular';
    src: url('./assets/fonts/Pacifico-Regular.ttf')
  }
  @font-face {
    font-family: 'SourceHanSansCN-Bold';
    src: url('./assets/fonts/SourceHanSansCN-Heavy.otf')
  }
  @font-face {
    font-family: 'SourceHanSansCN-Medium';
    src: url('./assets/fonts/SourceHanSansCN-Medium.otf')
  }
  @font-face {
    font-family: 'SourceHanSansCN-Light';
    src: url('./assets/fonts/SourceHanSansCN-Light.otf')
  }
  @font-face {
    font-family: 'SourceHanSansCN-Normal';
    src: url('./assets/fonts/SourceHanSansCN-Normal.otf')
  }
  @font-face {
    font-family: 'SourceHanSansCN-Regular';
    src: url('./assets/fonts/SourceHanSansCN-Regular.otf')
  }